import { jsx as t } from "react/jsx-runtime";
import { forwardRef as n } from "react";
import { Icon as f } from "../icon/Icon.es.js";
const u = n(
  function(o, r) {
    return /* @__PURE__ */ t(f, { ...o, ref: r });
  }
);
export {
  u as ButtonIcon
};
