import { styled as o } from "@linaria/react";
/* empty css                           */
const s = /* @__PURE__ */ o("footer")({
  name: "ModalFooter",
  class: "m1ephq6a",
  propsAsIs: !1
});
export {
  s as ModalFooter
};
