import { jsxs as l, jsx as e } from "react/jsx-runtime";
import { forwardRef as d, memo as i } from "react";
const h = ({ title: o, titleId: r, ...n }, t) => /* @__PURE__ */ l(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    width: "1em",
    height: "1em",
    ref: t,
    "aria-labelledby": r,
    ...n,
    children: [
      o ? /* @__PURE__ */ e("title", { id: r, children: o }) : null,
      /* @__PURE__ */ l("g", { fill: "none", fillRule: "evenodd", children: [
        /* @__PURE__ */ e("path", { fill: "currentColor", d: "M12 14 7 9h10z" }),
        /* @__PURE__ */ e("path", { d: "M0 0h24v24H0z" })
      ] })
    ]
  }
), m = d(h), s = i(m), w = s;
export {
  w as default
};
