import type { FC, ReactNode } from "react";

import { useErrorBoundary } from "../error";

export const PageErrorBoundary: FC<{ children: ReactNode }> = ({
  children
}) => {
  const ErrorBoundary = useErrorBoundary({
    type: "page"
  });
  return <ErrorBoundary>{children}</ErrorBoundary>;
};
