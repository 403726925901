import { useCallback, useState } from "react";

export const useToggleErrorStack = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    setIsVisible((prevState) => !prevState);
  }, []);

  return {
    isVisible,
    toggleVisibility
  };
};
