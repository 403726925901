import { jsx as e } from "react/jsx-runtime";
import { styled as m } from "@linaria/react";
import d from "clsx";
import { forwardRef as f } from "react";
/* empty css                        */
const c = /* @__PURE__ */ m("article")({
  name: "SModalBody",
  class: "s18933ss",
  propsAsIs: !1
}), u = f(function({
  className: o,
  children: s,
  mqDisableGutters: r = !1,
  mqAs: t = "article",
  ...a
}, l) {
  return /* @__PURE__ */ e(c, {
    ...a,
    as: t,
    ref: l,
    className: d(o, {
      "no-gutters": r
    }),
    children: s
  });
});
export {
  u as ModalBody,
  c as SModalBody
};
