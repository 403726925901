import { useCallback as n, useRef as o } from "react";
const f = (...c) => n(
  (r) => c.forEach((e) => {
    if (e) {
      if (typeof e == "function")
        return e(r);
      e.current = r;
    }
  }),
  [c]
), a = (c) => {
  const r = o(null);
  return {
    callbackRef: n((t) => {
      t && (r.current = t);
    }, []),
    ref: r
  };
};
export {
  f as useCombinedRefs,
  a as useForwardedRef
};
