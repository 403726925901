import { Button, ButtonIcon } from "@machineq/components";
import { CaretDown, CaretUp } from "@machineq/icons";
import { desktopStyles, makeColor, makeRem } from "@machineq/theme";
import type { FC } from "react";
import { styled } from "goober";

import { useToggleErrorStack } from "./error.useToggleErrorStack";

export const SPre = styled("pre")`
  padding: ${makeRem(12)};
  background: ${makeColor("grey-1")};
  font-size: ${makeRem(14)};
  width: 100%;
  overflow-x: auto;

  ${desktopStyles} {
    overflow-y: auto;
  }
`;

export const ErrorCodeBlock: FC<{ message?: string; stack?: string }> = ({
  message,
  stack
}) => {
  const { isVisible, toggleVisibility } = useToggleErrorStack();
  return (
    <>
      <div>
        <SPre>{message || "Unknown error. No message provided."}</SPre>
        <Button
          mqVariant="text"
          mqColor="primary"
          type="button"
          onClick={toggleVisibility}
          style={{
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <ButtonIcon
            Icon={isVisible ? CaretUp : CaretDown}
            mqAccessibility="decorative"
          />

          {isVisible ? "hide details" : "show details"}
        </Button>
        {isVisible && <SPre>{stack}</SPre>}
      </div>
    </>
  );
};
